import React, { useState } from 'react';
import Link from 'next/link';
import { MenuIcon } from '@heroicons/react/outline';
import Image from 'next/image';

export default function NavBar() {
  const [toggleMenu, setToggleMenu] = useState(false);

  function handleToggle() {
    setToggleMenu(!toggleMenu);
  }

  return (
    <div className="bg-gray-900">
      <header className="max-w-screen-xl mx-auto px-6 lg:px-8 xl:px-4 py-4 lg:py-6 md:flex justify-between">
        {/* <span className="sr-only">SaaS landing page</span> */}
        <div className="flex justify-between">
          <Link href="/">
            <a>
              <Image
                height={35}
                width={175}
                // layout="responsive"
                priority
                src="/images/logo.png"
              />
            </a>
          </Link>

          <MenuIcon
            className="md:hidden"
            onClick={handleToggle}
            style={{ height: '35px' }}
            src="/images/logo.png"
          />
        </div>

        <nav
          className={`w-full pt-5 md:pt-auto px-auto text-center md:flex items-center space-x-4 flex-col md:flex-row toggle md:w-auto ${
            toggleMenu ? '' : 'hidden'
          }`}
        >
          {/* <a
                        href="#"
                        className="flex space-x-1 items-center hover:text-white"
                    >
                        <svg
                            className="hidden sm:inline w-5 h-5"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                            ></path>
                        </svg>
                        <span>Sign in</span>
                    </a> */}
          <Link href="/features">
            <a
              onClick={handleToggle}
              className="analytics-plans-link md:flex space-x-1 items-center hover:text-white text-center"
            >
              <p>Features</p>
            </a>
          </Link>

          <Link href="/demo">
            <a
              onClick={handleToggle}
              className="analytics-view-demo-link md:flex space-x-1 items-center hover:text-white text-center"
            >
              <p>Request demo</p>
            </a>
          </Link>

          <Link href="/contact">
            <a
              onClick={handleToggle}
              className=" md:flex space-x-1 items-center hover:text-white text-center"
            >
              <p>Contact us</p>
            </a>
          </Link>

          <Link href="/get-started">
            <a
              onClick={handleToggle}
              className="analytics-contact-us-link primary-button mt-8 md:mt-auto md:flex space-x-1 items-center hover:text-white text-center"
            >
              <span className="font-bold flex items-baseline mx-auto">
                Get started
                <svg
                  className="w-4 h-4 ml-2 pt-1"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  />
                </svg>
              </span>
            </a>
          </Link>
        </nav>
      </header>
    </div>
  );
}
