import { GET_MESSAGES, CREATE_MESSAGE, GET_ERRORS } from '../../actions/messages/types';

const initialState = {
  title: '',
  message: '',
  type: '', // 'success', 'danger', 'info'
  time: '',
};

// function to verify object
function isObject(item) {
  return typeof item === 'object' && !Array.isArray(item) && !!item;
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const showAlert = (message, title, type) => {
  const msg = message;
  const alertType = type == null ? 'info' : type;

  // //fix issues with objects
  // const isLiteralObject = function (a) {
  //     return !!a && a.constructor === Object;
  // };
  // if (isLiteralObject(msg)) {
  //     for (const [key, value] of Object.entries(msg)) {
  //         msg = value;
  //     }
  // }
  // //

  if (title) {
    return {
      title,
      message: msg,
      type: alertType,
      time: new Date().toISOString(),
    };
  }
  return {
    title: '',
    message: msg,
    type: alertType,
    time: new Date().toISOString(),
  };
};

export default function messages(state = initialState, action) {
  switch (action.type) {
    //

    case GET_MESSAGES:
    case CREATE_MESSAGE:
      return {
        ...state,
        ...showAlert(action.payload.msg, null, action.payload.type),
      };
    //
    // case SHOW_NOTIFICATION:
    //     return {
    //         ...state,
    //         ...showAlert(action.payload.msg, action.payload.title)
    //     };
    // ERRORS
    // handle errors from django
    case GET_ERRORS:
      // add title
      var title = '';
      if (action.payload.title) {
        title = action.payload.title;
      }

      if (process.env.NODE_ENV === 'development') {
        console.log(action.payload.msg);
      }

      var err = action.payload.msg;

      if (isObject(err)) {
        //  deal with non field errors
        if (err.non_field_errors) {
          err = err.non_field_errors[0];
        } else if (err.error && err.error.error) {
          err = err.error.error;
        }
        // convert object to string
        else {
          let str = '';
          // convert err object to array [ key, val]
          const err_array = Object.entries(err);
          err_array.map((obj) => {
            const key = obj[0];
            const val = obj[1];
            if (typeof val === 'string') {
              // convert array to string
              // replace _ with ' '
              str = `${str + capitalizeFirstLetter(key.replace(/_/g, ' '))}: ${val}\n\n`;
            } else if (Array.isArray(val)) {
              // convert array to string
              // replace _ with ' '
              str = `${
                str + capitalizeFirstLetter(key.replace(/_/g, ' '))
              }: ${val[0].toString()}\n\n`;
            }
          });
          err = str;
        }
      } else if (typeof err === 'string') {
        //
        if (err.includes('<!DOCTYPE html>')) {
          err = `Failed: ${action.payload.title}`;
        }
      }

      return {
        ...state,
        ...showAlert(err, `ERROR: ${title}`, 'danger'),
      };
    default:
      return state;
  }
}
