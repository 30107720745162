import React, { useEffect } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import { Provider } from 'react-redux';
import Router from 'next/router';
import NProgress from 'nprogress';
import dynamic from 'next/dynamic';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import { useStore } from '../state/store';
/** *** */
import { init as initSentry } from '../lib/sentry';
import NavBar from '../layout/navbar';
import Footer from '../layout/footer';
import FAQ from '../components/faq';
import { GTMPageView } from '../lib/gtag';

// styles
// import "antd/dist/antd.css"; // or 'antd/dist/antd.less's
import 'antd/lib/slider/style/index.css';
import '../styles/globals.css';
import '../styles/index.css';
import 'nprogress/nprogress.css'; // styles of nprogress

//
import AlertTemplate from '../components/alerts/AlertTemplate';

const Alerts = dynamic(() => import('../components/alerts/Alerts'), {
  ssr: false,
});
// optional configuration for react-alert
const alertOptions = {
  position: positions.TOP_RIGHT,
  offset: '60px',
  transition: transitions.FADE,
};

// sentry
initSentry();

// Progress indicator
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const theme = extendTheme({
  styles: {
    global: {
      body: {
        fontFamily: null,
        lineHeight: null,
      },
    },
  },
  colors: {
    brand: {
      50: '#eef2ff',
      100: '#e0e7ff',
      200: '#c7d2fe',
      300: '#a5b4fc',
      400: '#818cf8',
      500: '#6366f1',
      600: '#4f46e5',
      700: '#4338ca',
      800: '#3730a3',
      900: '#312e81',
    },
  },
});

export default function App({ Component, pageProps, err }) {
  const store = useStore(pageProps.initialReduxState);

  // Initiate GTM
  useEffect(() => {
    const handleRouteChange = (url) => GTMPageView(url);
    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return (
    <Provider store={store}>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
        />
        <meta name="description" content="Description" />
        <meta name="keywords" content="Keywords" />
        <title>ShuleSuite</title>

        <link rel="manifest" href="/manifest.json" />
        <link href="/icons/favicon-16x16.png" rel="icon" type="image/png" sizes="16x16" />
        <link href="/icons/favicon-32x32.png" rel="icon" type="image/png" sizes="32x32" />
        <link rel="apple-touch-icon" href="/apple-icon.png" />
        <meta name="theme-color" content="#317EFB" />
      </Head>
      {/* Google Tag Manager - Global base code */}
      {process.env.NEXT_PUBLIC_DEPLOY_ENVIRONMENT == 'PRODUCTION' && (
        <>
          {/* <!-- Google Tag Manager --> */}
          <Script
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                                    })(window,document,'script','dataLayer','GTM-NJ7ZB4D');
                                    `,
            }}
          />
          {/* <!-- End Google Tag Manager --> */}
        </>
      )}
      <main className="bg-black text-gray-200 font-medium">
        <AlertProvider
          // template={AlertTemplate}
          {...alertOptions}
          template={AlertTemplate}
        >
          <ChakraProvider resetCSS={false} theme={theme}>
            <Alerts />
            {/* <!-- header block --> */}
            <NavBar />
            <Component {...pageProps} err={err} />
            <FAQ />
            <Footer />
          </ChakraProvider>
        </AlertProvider>
      </main>
    </Provider>
  );
}
