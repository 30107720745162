import {
  JOIN_WAIT_LIST_SUCCESS,
  JOIN_WAIT_LIST_LOADING,
  JOIN_WAIT_LIST_FAILED,
  CONTACT_US_SUCCESS,
  CONTACT_US_LOADING,
  CONTACT_US_FAILED,
  REQUEST_DEMO_SUCCESS,
  REQUEST_DEMO_LOADING,
  REQUEST_DEMO_FAILED,
  GET_STARTED_SUCCESS,
  GET_STARTED_LOADING,
  GET_STARTED_FAILED,
} from '../../actions/customers/types';

const initialState = {
  joinWaitListLoading: false,
  joinWaitListSuccess: false,
  joinWaitListFailed: false,
  contactUsLoading: false,
  contactUsSuccess: false,
  contactUsFailed: false,
  requestDemoLoading: false,
  requestDemoSuccess: false,
  requestDemoFailed: false,
  getStartedLoading: false,
  getStartedSuccess: false,
  getStartedFailed: false,
};

export default function Classes(state = initialState, action) {
  switch (action.type) {
    //
    case CONTACT_US_LOADING:
      return {
        ...state,
        contactUsLoading: true,
        contactUsSuccess: false,
        contactUsFailed: false,
      };
    case CONTACT_US_SUCCESS:
      return {
        ...state,
        contactUsLoading: false,
        contactUsFailed: false,
        contactUsSuccess: true,
      };
    case CONTACT_US_FAILED:
      return {
        ...state,
        contactUsFailed: true,
        contactUsLoading: false,
        contactUsSuccess: false,
      };
    //
    case GET_STARTED_LOADING:
      return {
        ...state,
        getStartedLoading: true,
        getStartedSuccess: false,
        getStartedFailed: false,
      };
    case GET_STARTED_SUCCESS:
      return {
        ...state,
        getStartedLoading: false,
        getStartedFailed: false,
        getStartedSuccess: true,
      };
    case GET_STARTED_FAILED:
      return {
        ...state,
        getStartedFailed: true,
        getStartedLoading: false,
        getStartedSuccess: false,
      };
    //
    case REQUEST_DEMO_LOADING:
      return {
        ...state,
        requestDemoLoading: true,
        requestDemoSuccess: false,
        requestDemoFailed: false,
      };
    case REQUEST_DEMO_SUCCESS:
      return {
        ...state,
        requestDemoLoading: false,
        requestDemoFailed: false,
        requestDemoSuccess: true,
      };
    case REQUEST_DEMO_FAILED:
      return {
        ...state,
        requestDemoFailed: true,
        requestDemoLoading: false,
        requestDemoSuccess: false,
      };
    //
    case JOIN_WAIT_LIST_LOADING:
      return {
        ...state,
        joinWaitListLoading: true,
        joinWaitListSuccess: false,
        joinWaitListFailed: false,
      };
    case JOIN_WAIT_LIST_SUCCESS:
      return {
        ...state,
        joinWaitListLoading: false,
        joinWaitListFailed: false,
        joinWaitListSuccess: true,
      };
    case JOIN_WAIT_LIST_FAILED:
      return {
        ...state,
        joinWaitListFailed: true,
        joinWaitListLoading: false,
        joinWaitListSuccess: false,
      };

    //
    default:
      return state;
  }
}
