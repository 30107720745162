export const JOIN_WAIT_LIST_LOADING = 'JOIN_WAIT_LIST_LOADING';
export const JOIN_WAIT_LIST_FAILED = 'JOIN_WAIT_LIST_FAILED';
export const JOIN_WAIT_LIST_SUCCESS = 'JOIN_WAIT_LIST_SUCCESS';
//
export const CONTACT_US_LOADING = 'CONTACT_US_LOADING';
export const CONTACT_US_FAILED = 'CONTACT_US_FAILED';
export const CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS';
//
export const REQUEST_DEMO_LOADING = 'REQUEST_DEMO_LOADING';
export const REQUEST_DEMO_FAILED = 'REQUEST_DEMO_FAILED';
export const REQUEST_DEMO_SUCCESS = 'REQUEST_DEMO_SUCCESS';
//
export const GET_STARTED_LOADING = 'GET_STARTED_LOADING';
export const GET_STARTED_FAILED = 'GET_STARTED_FAILED';
export const GET_STARTED_SUCCESS = 'GET_STARTED_SUCCESS';
