import React, { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';

function Footer() {
  return (
    <footer className="bg-gray-900 text-gray-200 py-12 xl:pb-24 border-t-2 border-gray-700">
      <div className="max-w-screen-xl mx-auto px-6 lg:px-8 xl:px-4 grid md:grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-x-8">
        <div>
          <div className="max-w-screen-xl mx-auto px-6 lg:px-8 xl:px-4 mb-12 lg:mb-16">
            <Link href="/">
              <a>
                <Image
                  height={35}
                  width={175}
                  // layout="responsive"
                  // priority
                  src="/images/logo.png"
                />
              </a>
            </Link>
          </div>
        </div>
        <div>
          {/* <h5 className="text-xl font-bold text-gray-300">
                            Product
                        </h5> */}
          <nav>
            <ul className="space-y-2" style={{ listStyleType: 'none' }}>
              <li>
                <Link href="/get-started">
                  <a className="analytics-plans-link text-base hover:text-gray-500">
                    <span className="font-bold  flex items-baseline">
                      Get started
                      <svg
                        className="w-4 h-4 ml-2 pt-1"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M14 5l7 7m0 0l-7 7m7-7H3"
                        />
                      </svg>
                    </span>
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/features">
                  <a className="analytics-plans-link text-base hover:text-gray-500">Features</a>
                </Link>
              </li>
              <li>
                <Link href="/demo">
                  <a className="analytics-view-demo-link text-base hover:text-gray-500">
                    Request demo
                  </a>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div>
          {/* <h5 className="text-xl font-bold text-gray-300">
                            Schools
                        </h5> */}
          <nav>
            <ul className="space-y-2" style={{ listStyleType: 'none' }}>
              <li>
                <Link href="/">
                  <a className="text-base hover:text-gray-500">Home</a>
                </Link>
              </li>
              <li>
                <Link href="/contact">
                  <a className="analytics-contact-us-link text-base hover:text-gray-500">
                    Contact us
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/pricing">
                  <a className="analytics-pricing-link text-base hover:text-gray-500">
                    Pricing
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/policies/terms-and-conditions">
                  <a className="text-base hover:text-gray-500">Terms and conditions</a>
                </Link>
              </li>
              <li>
                <Link href="/policies/privacy-policy">
                  <a className="text-base hover:text-gray-500">Privacy policy</a>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        {/* <div>
                        <h5 className="text-xl font-bold text-gray-300">
                            About us
                        </h5>
                        <nav >
                            <ul
                                className="space-y-2"
                                style={{ listStyleType: "none" }}
                            >
                                <li>
                                    <a
                                        href="#"
                                        className="text-base hover:text-gray-500"
                                    >
                                        Company
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        className="text-base hover:text-gray-500"
                                    >
                                        Download brochure
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        className="text-base hover:text-gray-500"
                                    >
                                        Resources
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div> */}
        {/* <div>
                        <h5 className="text-xl font-bold text-gray-300">
                            Legal
                        </h5>
                        <nav >
                            <ul
                                className="space-y-2"
                                style={{ listStyleType: "none" }}
                            >
                                <li>
                                    <a
                                        href="#"
                                        className="text-base hover:text-gray-500"
                                    >
                                        Terms and conditions
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        className="text-base hover:text-gray-500"
                                    >
                                        Security
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        className="text-base hover:text-gray-500"
                                    >
                                        Privacy
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div> */}
      </div>

      <div className="max-w-screen-xl mx-auto px-6 lg:px-8 xl:px-4 flex flex-col md:flex-row justify-between items-center space-y-4 mt-8 lg:mt-12">
        <div className="text-sm space-y-4 md:space-y-1 text-center md:text-left">
          <p>&copy;{new Date().getFullYear()} ShuleSuite Software. All rights reserved.</p>
          {/* <p>
                            Wisdom is easily acquired when hiding under the bed
                            with a saucepan on your head.
                        </p> */}
        </div>
        <nav className="flex items-center space-x-2">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://web.facebook.com/ShuleSuite"
            className="text-gray-500 hover:text-gray-200"
          >
            <span className="sr-only">Facebook</span>
            <svg className="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
              <path
                fillRule="evenodd"
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                clipRule="evenodd"
              />
            </svg>
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/ShuleSuite"
            className="text-gray-500 hover:text-gray-200"
          >
            <span className="sr-only">Twitter</span>
            <svg className="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
            </svg>
          </a>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;
